<template>
  <pages-badge h1="POMPY" bg="hero.jpg">
    <template v-slot:default>
      <section-badge h1="Pompy wentylacyjne">
        <template v-slot:default>
          <div class="cards">
            <product-card v-for="card in cards" :key="card" :card="card" />
          </div>
        </template>
      </section-badge>
    </template>
  </pages-badge>
</template>

<script>
import cardsArray from '../../../cards.js';
export default {
  computed: {
    cards() {
      return this.cardsFilter(cardsArray.cards, 'wentylacyjna');
    },
  },
  methods:{
    cardsFilter(cards, type){
      return cards.filter((card => card.type === type))
    }
  }
};
</script>

<style scoped>
.cards {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
</style>
