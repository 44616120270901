<template>
  <footer>
    <div class="flex">
      <div class="box">
        <h1>KONTAKT</h1>
        <ul>
          <li>KUNZKLIMA - Marcin Kunz</li>
          <li>ul. spacerowa 30</li>
          <li>84-215 Gowino</li>
          <li><a href="mailto:biuro@kunzklima.pl">biuro@kunzklima.pl</a></li>
          <li><a href="callto:+48608381322">tel. +48 608 381 322</a></li>
        </ul>
      </div>
      <div class="box">
        <h1>NA SKRÓTY</h1>
        <ul class="links">
          <router-link to="/pompy"><li>Pompy ciepła</li></router-link>
          <router-link to="/klimatyzatory"><li>Klimatyzatory</li></router-link>
          <router-link to="/instalacje"><li>Instalacje</li></router-link>
          <router-link to="/kontakt"><li>Kontakt</li></router-link>
        </ul>
      </div>
      <div class="box">
        <h1>MEDIA</h1>
        <ul class="media__container">
          <bounce-icon
            ><li>
              <a target="blank" href="https://www.facebook.com/kminstal1"
                ><img src="/icons/fb.svg" alt=""
              /></a></li
          ></bounce-icon>
          <bounce-icon
            ><li>
              <a href="mailto:biuro@kunzklima.pl"
                ><img src="/icons/mail.svg" alt=""
              /></a></li
          ></bounce-icon>
          <bounce-icon
            ><li>
              <a href="callto:+48608381322"
                ><img src="/icons/phone.svg" alt=""
              /></a></li
          ></bounce-icon>
        </ul>
      </div>
    </div>
    <div class="small__footer">
      <span>Copyright KM-Instal 2023</span>
      <span
        ><a target="blank" href="https://nicode.pl"
          >PoweredBy NiCode.pl</a
        ></span
      >
    </div>
  </footer>
</template>

<script>
import BounceIcon from "../animations/BounceIcon.vue";
export default {
  components: { BounceIcon },
};
</script>

<style scoped>
footer {
  position: relative;
  width: 100%;
  background-color: #eeeeee;
  padding: 2rem;
  padding-bottom: 4rem;
  overflow: hidden;
}
.small__footer {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 7%;
  background-color: #0074d9;
  color: white;
  bottom: 0;
  left: 0;
  font-weight: 300;
  font-size: 0.8rem;
}
.flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0.5rem;
}
.box {
  min-width: 200px;
  overflow: visible;
}
.box h1 {
  text-align: left;
}
ul {
  text-align: left;
  overflow: visible;
}
.links li {
  color: #838383;
  cursor: pointer;
  transition: 0.3s all;
}
.links li:hover {
  color: black;
}
.media__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  max-width: 70%;
  overflow: visible;
}
.media__container img {
  max-width: 40px;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .flex {
    flex-direction: column;
    gap: 2rem;
  }
}
</style>
