import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
const app = createApp(App)

/* Import FA */
/* FONTAWESOME */
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
library.add(faX);

import SectionBadge from './components/utils/SectionBadge.vue'
import PagesBadge from './components/utils/PagesBadge.vue'
import ProductCard from './components/UI/ProductCard.vue'
/* COMPONENTS */ // ANIMATIONS:
import BounceIcon from './components/animations/BounceIcon.vue'
import TheLoading from './components/SVGs/TheLoading.vue'

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('section-badge', SectionBadge)
app.component('pages-badge', PagesBadge)
app.component('product-card', ProductCard)
app.component('the-loading', TheLoading)
app.component('bounce-icon', BounceIcon)

app.use(store);
app.use(router);
app.mount('#app')
