<template>
  <div class="form">
    <form ref="form" @submit.prevent="sendEmail">
      <div class="input__group">
        <input
          required
          :class="{ invalid: nameValidity === 'invalid' }"
          v-model.trim="from_name"
          type="text"
          name="from_name"
          placeholder="Imię i Nazwisko *"
          @blur="validateInputName"
        />
        <p v-if="nameValidity === 'invalid'">to pole nie może być puste</p>
      </div>
      <div class="input__group">
        <input
          v-model="phone"
          type="text"
          name="phone"
          placeholder="Numer telefonu"
        />
      </div>
      <div class="input__group">
        <input
          required
          :class="{ invalid: mailValidity === 'invalid' }"
          v-model="from_email"
          type="from_email"
          name="from_email"
          placeholder="Email *"
          @blur="validateInputMail"
        />
        <p v-if="mailValidity === 'invalid'">to pole nie może być puste</p>
      </div>
      <div class="input__group">
        <textarea
          required
          :class="{ invalid: contentValidity === 'invalid' }"
          v-model="mailContent"
          name="mailContent"
          placeholder="Opis *"
          @blur="validateInputContent"
        ></textarea>
        <p v-if="contentValidity === 'invalid'">to pole nie może być puste</p>
      </div>
      <div class="input__group__CheckBox">
        <input
          class="checkbox__input"
          type="checkbox"
          name="myCheckboxName"
          required
        />
        <h1>
          * Wyrażam dobrowolną zgodę na przetwarzanie przez KM-Instal - Marcin
          Kunz, moich danych osobowych zawartych w tym formularzu w celu i
          zakresie niezbędnym do odpowiedzi na to pytanie. Potwierdzam
          zapoznanie się z
          <a href="Rodo.docx" download>polityką prywatności.</a>
        </h1>
      </div>
      <div class="input__group">
        <button type="submit" value="Send" required>Wyślij</button>
      </div>
      <div class="loading__container">
        <the-loading
          v-if="waiting"
          :color="'#0074D9'"
          :color2="'#0074D9'"
        ></the-loading>
      </div>
    </form>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
export default {
  data() {
    return {
      from_name: null,
      phone: null,
      from_email: null,
      mailContent: null,
      sendValidity: "",
      waiting: false,

      nameValidity: "pending",
      mailValidity: "pending",
      contentValidity: "pending",
    };
  },
  methods: {
    cleanInputs() {
      this.from_name = "";
      this.phone = "";
      this.from_email = "";
      this.mailContent = "";
    },
    sendEmail() {
      this.waiting = true;
      this.cleanInputs();

      emailjs
        .sendForm(
          "KMInstal",
          "template_cj1k1jl",
          this.$refs.form,
          "W85IhI6J0b5o6LuHy",
          {
            phone: this.phone,
            from_name: this.from_name,
            from_email: this.from_email,
            emailContent: this.emailContent,
          }
        )
        .then(
          () => {
            this.$emit("send-validity", "success");
            this.sendValidity = "success";
            this.waiting = false;
          },
          (error) => {
            console.log("FAILED...", error.text);
            this.$emit("send-validity", "failed");
            this.sendValidity = "failed";
            this.waiting = false;
          }
        );
    },
    validateInputName() {
      if (this.from_name === "") {
        this.nameValidity = "invalid";
      } else {
        this.nameValidity = "valid";
      }
    },
    validateInputMail() {
      if (this.from_email === "") {
        this.mailValidity = "invalid";
      } else {
        this.mailValidity = "valid";
      }
    },
    validateInputContent() {
      if (this.mailContent === "") {
        this.contentValidity = "invalid";
      } else {
        this.contentValidity = "valid";
      }
    },
  },
};
</script>

<style scoped>
.form {
  padding: 3rem;
  align-self: center;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.25);
  margin: 5rem;
}
.form h1 {
  font-size: 1.5rem;
  color: #ffffff;
}
.input__group {
  position: relative;
}
.input__group p {
  font-size: 0.7rem;
  transition: 0.3s all;
  padding: 0.2rem 0.5rem;
  color: red;
}
.invalid {
  border-bottom: 1px red solid;
}
.red {
  color: red;
}
.input__group,
h1 {
  margin: 1.5rem 1rem;
}
input,
textarea {
  color: #0074d9;
  border: none;
  border-bottom: 1px #0074d9 solid;
  padding: 1rem;
  transition: 0.3s all;
  background-color: transparent;
}
input:focus,
textarea:focus {
  outline: none;
  border-bottom: 1px rgba(0, 116, 217, 0.369) solid;
}
input,
textarea,
button {
  height: 2.5rem;
  width: 100%;
  background-color: #f5f5f5;
}
textarea {
  max-width: 450px;
  max-height: 200px;
  min-height: 4rem;
  padding: 0.5rem 0.5rem;
}
button {
  background-color: #0074d9;
  border: none;
  color: #e5e5e5;
  font-size: 1rem;
  cursor: pointer;
  height: 3rem;
  transition: 0.3s all;
}
button:hover {
  background-color: rgba(0, 116, 217, 0.369);
}
label {
  position: absolute;
  left: 0.5rem;
  top: 0.6rem;
  font-size: 0.8rem;
}
.input__group__CheckBox {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  max-width: 400px;
  margin: 1rem 1rem;
}
.input__group__CheckBox a {
  color: #0074d9;
  text-decoration: underline;
}
.input__group__CheckBox input {
  width: unset;
  height: unset;
  box-shadow: unset;
  cursor: pointer;
}
.input__group__CheckBox input {
  background-color: #0074d9;
}
.input__group__CheckBox h1 {
  margin: 0 0.5rem;
  color: rgb(179, 179, 179);
  font-size: 0.6rem;
  text-align: justify;
}
.loading__container {
  margin: 0;
  align-self: center;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}
.checkbox__input {
  width: 40px !important;
  height: 40px !important;
}
@media screen and (max-width: 800px) {
  .form {
    padding: 1rem;
    margin: 0;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #0074d9;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
