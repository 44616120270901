<template>
  <div class="card">
    <div class="content">
      <h1>{{ h1 }}</h1>
      <div class="line"></div>
      <h2>{{ h2 }}</h2>
      <router-link :to="`/pompy/${link}`">
        <div class="btn">
          <h1>Sprawdź!</h1>
          <img src="/icons/nav-arrow-down.svg" alt="" />
        </div>
      </router-link>
    </div>
    <div
      class="photo"
      :style="{ backgroundImage: `url(/pompy/kinds/${img})` }"
    ></div>
  </div>
</template>

<script>
export default {
  props: ["h1", "h2", "img", "link"],
};
</script>

<style scoped>
.card {
  background-color: #a8c5de;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  text-align: left;
  position: relative;
  overflow: hidden;
  min-height: 350px;
  padding: 2%;
}
.content {
  z-index: 5;
  padding: 2rem;
}
.photo {
  position: absolute;
  right: 2rem;
  bottom: 0;
  width: 300px;
  height: 300px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 4;
}
.line {
  width: 7%;
  height: 4px;
  background-color: #0074d9;
  margin: 1rem 0;
}
.card h1,
.card h2 {
  color: rgb(0, 53, 100);
}
.card h1 {
  max-width: 60%;
}
.card h2 {
  font-size: 0.9rem;
  font-weight: 400;
  max-width: 60%;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #0074d9;
  width: fit-content;
  padding: 0.15rem 1.5rem;
  margin-top: 1.5rem;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
}
.btn::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  bottom: 0;
  left: 0;
  transition: 0.3s all;
  background-color: rgb(0, 56, 105);
  z-index: 1;
}
.btn:hover.btn::before {
  width: 100%;
}
.btn h1 {
  font-size: 1rem;
  color: white;
  font-weight: 300;
  z-index: 3;
}
.btn img {
  margin-left: 1rem;
  width: 30px;
  z-index: 3;
}
@media screen and (max-width: 1000px) {
  .content {
    background-color: rgba(255, 255, 255, 0.7);
  }
  .card h1 {
    max-width: 100%;
    font-size: 1.2rem;
  }
  .line {
    width: 20%;
    height: 3px;
    margin: 1rem 0;
  }
  .card h2 {
    font-size: 0.8rem;
    max-width: 95%;
  }
  .photo {
    opacity: 0.5;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #0074d9;
    width: fit-content;
    padding: 0.35rem 1.5rem;
    margin-top: 1rem;
    border-radius: 2px;
  }
  .btn h1 {
    font-size: 0.8rem;
    color: white;
    font-weight: 300;
  }
  .btn img {
    margin-left: 0.7rem;
    width: 20px;
  }
  .photo {
    position: absolute;
    right: -8rem;
    bottom: 0;
    width: 300px;
    height: 300px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 4;
  }
}
</style>
