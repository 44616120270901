import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import KlimatyzatoryView from '../views/KlimatyzatoryView.vue';
import PompyPlatesSection from '../components/UI/pompy/PompyPlatesSection.vue';
import PompyPowierzne from '../components/UI/pompy/PompyPowietrzne.vue';
import PompyGruntowe from '../components/UI/pompy/PompyGruntowe.vue';
import PompyWentylacyjne from '../components/UI/pompy/PompyWentylacyjne.vue';
import RealizacjeView from '../views/RealizacjeView.vue';
import ContactView from '../views/ContactView.vue';
import InstalacjeView from '../views/InstalacjeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/klimatyzatory',
    name: 'klimatyzatory',
    component: KlimatyzatoryView,
  },
  {
    path: '/pompy',
    name: 'pompy',
    component: PompyPlatesSection,
  },

  {
    props: true,
    path: '/pompy/powietrzne',
    name: 'powietrzne',
    component: PompyPowierzne,
  },
  
  {
    props: true,
    path: '/pompy/gruntowe',
    name: 'gruntowe',
    component: PompyGruntowe,
  },
  {
    props: true,
    path: '/pompy/wentylacyjne',
    name: 'wentylacyjne',
    component: PompyWentylacyjne,
  },
  {
    path: '/realizacje',
    name: 'realizacje',
    component: RealizacjeView,
  },

  {
    path: '/kontakt',
    name: 'kontakt',
    component: ContactView,
  },
  {
    path: '/instalacje',
    name: 'instalacje',
    component: InstalacjeView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
