<template>
  <pages-badge h1="Instalacje" bg="hero.jpg">
    <template v-slot:default>
      <section-badge :withoutLine="true" :overflow="true">
        <template v-slot:default>
          <div class="container">
            <h1>
              Proces instalacji pomp ciepła i klimatyzatorów jest skomplikowany
              i wymaga specjalistycznej wiedzy oraz doświadczenia. Poniżej
              przedstawiam opis ogólny procesu instalacji.
            </h1>
            <div class="line"></div>
            <div class="instalacje__container">
              <div class="box">
                <h2>
                  Konsultacje i projektowanie - W pierwszej fazie nasza firma
                  skonsultuje się z klientem, aby zrozumieć jego potrzeby i
                  wymagania. Na podstawie tych informacji, nasz zespół
                  projektowy stworzy plan instalacji, który uwzględni
                  indywidualne wymagania klienta oraz warunki techniczne miejsca
                  instalacji.
                </h2>
                <h3>1</h3>
                <div class="img">
                  <img src="/icons/meet.svg" alt="" />
                </div>
              </div>
              <div class="box">
                <h2>
                  Montaż - W drugiej fazie, nasi specjaliści przystąpią do
                  instalacji pomp ciepła lub klimatyzatora. Przed rozpoczęciem
                  pracy, nasi technicy wykonają niezbędne pomiary i przeprowadzą
                  kontrolę stanu technicznego urządzenia. Następnie dokonają
                  montażu urządzenia, włączając w to prace hydrauliczne,
                  elektryczne oraz konfigurację systemu.
                </h2>
                <h3>2</h3>
                <div class="img">
                  <img src="/icons/work.svg" alt="" />
                </div>
              </div>
              <div class="box">
                <h2>
                  Testowanie i uruchomienie - Po zakończeniu montażu, nasi
                  technicy przeprowadzą testy urządzenia, aby upewnić się, że
                  działa ono poprawnie i spełnia wymagania klienta. Przetestują
                  także system kontrolny oraz przeprowadzą szkolenie klienta w
                  zakresie obsługi i konserwacji urządzenia.
                </h2>
                <h3>3</h3>
                <div class="img">
                  <img src="/icons/test.svg" alt="" />
                </div>
              </div>
              <div class="box">
                <h3>4</h3>
                <h2>
                  Serwis i wsparcie - Nasza firma oferuje również serwis i
                  wsparcie techniczne dla urządzeń, które instalujemy. Nasz
                  zespół specjalistów jest dostępny dla klientów przez cały
                  czas, aby odpowiedzieć na wszelkie pytania, pomóc w
                  rozwiązywaniu problemów i przeprowadzić regularne przeglądy
                  techniczne urządzenia.
                </h2>

                <div class="img">
                  <img src="/icons/help.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </section-badge>
    </template>
  </pages-badge>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  margin-top: -2rem;
  width: 100%;
}
.instalacje__container {
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
* {
  text-align: left;
}
h1 {
  width: 75%;
  font-weight: 500;
}
h3 {
  position: absolute;
  z-index: 0;
  font-size: 20rem;
  opacity: 0.1;
}
.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem;
  background-color: #f1f1f1;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.box h2 {
  font-size: 1.1rem;
  max-width: 50%;
  font-weight: 400;
  color: #2f2e41;
}
.box img {
  max-width: 300px;
}
.line {
  width: 10%;
  height: 3px;
  background-color: #0074d9;
  margin: 1.5rem 0;
}
@media screen and (max-width: 800px) {
  .box img {
    position: absolute;
    right: 0;
    opacity: 0.8;
  }
  h1 {
    font-size: 1.3rem;
    max-width: 85%;
  }
  .box h2 {
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 3rem;
    max-width: 100%;
  }
  .box {
    padding: 1rem;
  }
  h3 {
    opacity: 0.3;
    font-size: 17rem;
    left: 2rem;
  }
}
</style>
