export default{
  cards: [
    ////////////////HEIZTECHNIK
    {
      type: 'powietrzna',
      producer: 'Heiztechnik',
      model: 'Calla Verde Basic',
      advantages: [
        'Pompa Heiztechnik Calla Verde Basic zapewnia wysoką wydajność energetyczną dzięki zastosowaniu innowacyjnej technologii, która pozwala oszczędzać na kosztach energii.',
        'Dzięki specjalnej konstrukcji pompa Calla Verde Basic charakteryzuje się niskim poziomem hałasu, co sprawia, że jest to rozwiązanie idealne dla domów jednorodzinnych, mieszkań i innych pomieszczeń.',
      ],
      link: 'https://heiztechnik.pl/pompy-ciepla/calla-verde-basic/',
      img: '/photos/heiz1.png'
    },
    {
      type: 'powietrzna',
      producer: 'Heiztechnik',
      model: 'Calla Verde Style',
      advantages: [
        'Charakteryzuje się eleganckim wyglądem, który pozwala na integrację z różnymi stylami wnętrz. Dzięki temu pompa nie tylko spełnia swoją funkcję, ale również stanowi ozdobę pomieszczenia.',
        'Wykorzystuje zaawansowaną technologię, która zapewnia wysoką wydajność energetyczną. Dzięki temu pompa umożliwia oszczędzanie kosztów energii i przyczynia się do ochrony środowiska.',
      ],
      link: 'https://heiztechnik.pl/pompy-ciepla/calla-verde-style/',
      img: '/photos/heiz2.png'
    },
    {
      type: 'powietrzna',
      producer: 'Heiztechnik',
      model: 'Calla Verde Comfort',
      advantages: [
        'Charakteryzuje się wysoką wydajnością, co umożliwia jej efektywne ogrzewanie budynków. Dzięki temu pompa jest idealnym rozwiązaniem dla dużych domów, budynków użyteczności publicznej czy obiektów przemysłowych.',
        'Wykorzystuje innowacyjne technologie, takie jak sterowanie inteligentne czy pomiar temperatury, co umożliwia precyzyjne dostosowanie jej pracy do indywidualnych potrzeb i warunków panujących w pomieszczeniu.'
      ],
      link: 'https://heiztechnik.pl/pompy-ciepla/calla-verde-comfort/',
      img: '/photos/heiz3.png'
    },
    {
      type: 'powietrzna',
      producer: 'Heiztechnik',
      model: 'Calla Verde Comfort II',
      advantages: [
        'Zapewnia wysoką wydajność energetyczną, dzięki czemu jest efektywnym narzędziem do ogrzewania budynków.',
        'Charakteryzuje się niskim poziomem hałasu, co sprawia, że jest to idealne rozwiązanie dla domów, mieszkań czy biur, gdzie cicha praca urządzenia jest bardzo istotna.',
      ],
      link: 'https://heiztechnik.pl/pompy-ciepla/calla-verde-comfort-ii/',
      img: '/photos/heiz3.png'
    },
    ///////////////////////////////////////////NIBE
    /////////////////////Powietrzne
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'VVM S320',
      advantages: [
        'W połączeniu z powietrzną pompą ciepła NIBE tworzy zintegrowany system grzewczy',
        'Zintegrowana łączność bezprzewodowa',
        'Inteligentny, przyjazny dla użytkownika system sterowania'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/vvm-s320',
      img: 'https://www.nibe.eu/assets/product/2261/NIBE_VVMS320-cut.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'S2125',
      advantages: [
        'Wysoki sezonowy współczynnik efektywności SCOP* oraz niskie koszty eksploatacji',
        'Zakres pracy do 75°C temperatury zasilania oraz 65°C przy temperaturze zewnętrznej –25°C',
        'Niski poziom ciśnienia akustycznego do 39 dB (A)'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/s2125',
      img: 'https://www.nibe.eu/assets/product/5046/NIBE-S2125.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'F2120',
      advantages: [
        'Kompletny system z jednostką wewnętrzną NIBE dla domów jednorodzinnych, domów szeregowych i większych nieruchomości',
        'Technologia inwerterowa dla uzyskania maksymalnych oszczędności',
        'Podgląd i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Posiada funkcję chłodzenia'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/f2120',
      img: 'https://www.nibe.eu/assets/product/4941/NIBE_F2120_8kW.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'F2040',
      advantages: [
        'Kompletny system z jednostką wewnętrzną NIBE dla domów jednorodzinnych, domów szeregowych i większych nieruchomości',
        'Sterowanie inwerterowe dla uzyskania maksymalnych oszczędności',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Posiada funkcję chłodzenia'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/f2040',
      img: 'https://www.nibe.eu/assets/product/4854/NIBE-F2040.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'SPLIT AMS HBS',
      advantages: [
        'Kompletny system z modułem wewnętrznym NIBE dla domów wolnostojących i szeregowych',
        'Sterowanie inwerterowe dla uzyskania maksymalnych oszczędności',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Czynnik chłodniczy krąży między pompą ciepła a centralą wewnętrzną'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/split-hbs',
      img: 'https://www.nibe.eu/assets/product/4856/NIBE-Split-HBS05.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'VVM 225',
      advantages: [
        'Kompletny system z modułem wewnętrznym NIBE dla domów wolnostojących i szeregowych',
        'Dzięki bogatej ofercie wyposażenia dodatkowego, pozwala sterować na przykład układami chłodzenia, podgrzewania wody w basenie i innymi układami ogrzewania/chłodzenia.',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Wbudowany podgrzewacz wody.'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/vvm-225',
      img: 'https://www.nibe.eu/assets/product/125/NIBE_VVM225%20Cut.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'VVM 310',
      advantages: [
        'Elastyczny układ z pompą powietrzną NIBE dla domów wolnostojących i szeregowych',
        'Dzięki bogatej ofercie wyposażenia dodatkowego, pozwala sterować na przykład układami chłodzenia, podgrzewania wody w basenie i innymi układami ogrzewania/chłodzenia.',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Wbudowany podgrzewacz wody.'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/vvm-310',
      img: 'https://www.nibe.eu/assets/product/3385/NIBE-VVM310-VVM320-cut.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'VVM 320',
      advantages: [
        'Elastyczny układ z pompą powietrzną NIBE dla domów wolnostojących i szeregowych',
        'Dzięki bogatej ofercie wyposażenia dodatkowego, pozwala sterować na przykład układami chłodzenia, podgrzewania wody w basenie i innymi układami ogrzewania/chłodzenia.',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Wbudowany podgrzewacz wody.'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/vvm-320',
      img: 'https://www.nibe.eu/assets/product/3390/NIBE-VVM310VVM320-cut.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'VVM 500',
      advantages: [
        'Elastyczny układ z pompą powietrzną NIBE dla domów wolnostojących i szeregowych',
        'Dzięki bogatej ofercie wyposażenia dodatkowego, pozwala sterować na przykład układami chłodzenia, podgrzewania wody w basenie i innymi układami ogrzewania/chłodzenia.',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Wbudowany podgrzewacz wody.'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/vvm-500',
      img: 'https://www.nibe.eu/assets/product/3391/VVM-500-cut.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'SPLIT BA-SVM',
      advantages: [
        'Kompletny układ z pompą powietrzną NIBE typu split dla domów nowych i termomodernizowanych',
        'Nadzór i sterowanie przez intuicyjny sterownik z kolorowym wyświetlaczem lub przez NIBE Uplink jako element domu inteligentnego',
        'Wbudowany zasobnik wody o pojemności 180 l'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/ba-svm',
      img: 'https://www.nibe.eu/assets/product/2797/BA-SVM.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'SMO S40',
      advantages: [
        'Rozwiązanie dla nieruchomości z kaskadą maksymalnie ośmiu powietrznych pomp ciepła.',
        'W połączeniu z powietrzną pompą ciepła NIBE stanowi element energooszczędnego domu inteligentnego.',
        'Inteligentny, przyjazny dla użytkownika system ze sterowaniem za pomocą ekranu dotykowego - dla zapewnienia maksymalnej elastyczności.'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/smo-s40',
      img: 'https://www.nibe.eu/assets/product/4855/NIBE-SMO-S40.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'SMO 20',
      advantages: [
        'Moduł sterujący dla układu SPLIT z powietrzną pompą ciepła NIBE',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/smo-20',
      img: 'https://www.nibe.eu/assets/product/263/SMO20-colour-display.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'SHK 200M',
      advantages: [
        'Kompletny układ z pompą powietrzną NIBE typu monoblok dla domów nowych i termomodernizowanych',
        'Nadzór i sterowanie przez intuicyjny sterownik z kolorowym wyświetlaczem lub przez Internet za pomocą wiryny myUplink.com'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/shk-200m',
      img: 'https://www.nibe.eu/assets/product/2737/SHK%20200s_cut.png?width=210'
    },
    {
      type: 'powietrzna',
      producer: 'Nibe',
      model: 'SMO 40',
      advantages: [
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Dzięki bogatej ofercie wyposażenia dodatkowego, pozwala sterować na przykład układami chłodzenia, podgrzewania wody w basenie i innymi układami ogrzewania/chłodzenia.',
        'Do jednego systemu sterowania można podłączyć do ośmiu powietrznych pomp ciepła'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/powietrzne-pompy-ciepla/smo-40',
      img: 'https://www.nibe.eu/assets/product/4940/SMO-40-open.png?width=210'
    },
    ///////////////////////////////////////////NIBE
    /////////////////////Gruntowe
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'S1255 PC',
      advantages: [
        'Wysoki współczynnik efektywności sezonowej i minimalne koszty użytkowania.',
        'Pompa ciepła ze zintegrowanym zbiornikiem wody gorącej i chłodzeniem.',
        'Nadzór i sterowanie za pomocą myUplink jako element domu inteligentnego.',
        'Zintegrowana łączność bezprzewodowa ',
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/s1255-pc',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/44233/storage/MDQ0MjMzLzAvd2ViLTQ0M3gzODgtcG5n'
    },
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'S1255',
      advantages: [
        'Przyjazne dla użytkownika sterowanie na ekranie dotykowym',
        'Energooszczędna inteligentna technika cyfrowa',
        'Sterowanie za pomocą aplikacji MyUplink',
        'Zintegrowana łączność bezprzewodowa ',
        'Trzy wielkości mocy wyjściowej i zaawansowana technika inwerterowa w celu optymalnego dopasowania do potrzeb klienta.',
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/s1255',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/44233/storage/MDQ0MjMzLzAvd2ViLTQ0M3gzODgtcG5n'
    },
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'S1155 PC',
      advantages: [
        'Wysoki współczynnik efektywności sezonowej i minimalne koszty użytkowania.',
        'Zintegrowane chłodzenie  ',
        'Nadzór i sterowanie za pomocą myUplink jako element domu inteligentnego.',
        'Zintegrowana łączność bezprzewodowa '
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/s1155-pc',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/43959/storage/MDQzOTU5LzAvd2ViLTQ0M3gzODgtcG5n'
    },
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'S1155',
      advantages: [
        'Przyjazne dla użytkownika sterowanie na ekranie dotykowym',
        'Energooszczędna inteligentna technika cyfrowa',
        'Wiodąca technika inwerterowa i oddzielny zbiornik ciepłej wody użytkowej w celu optymalnego dopasowania do potrzeb.',
        'Zintegrowana łączność bezprzewodowa '
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/s1155',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/43959/storage/MDQzOTU5LzAvd2ViLTQ0M3gzODgtcG5n'
    },
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'F1245',
      advantages: [
        'Dla domów wolnostojących i szeregowych',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Dzięki bogatej ofercie wyposażenia dodatkowego firmy NIBE, pozwala sterować na przykład układami chłodzenia, podgrzewania wody w basenie i innymi układami ogrzewania/chłodzenia.',
        'Wbudowany podgrzewacz wody'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/f1245',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/67004/storage/MDY3MDA0LzAvd2ViLTQ0M3gzODgtcG5n'
    },
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'F1245 PC',
      advantages: [
        'Wielofunkcyjna pompa ciepła ze zbiornikiem wody gorącej i zintegrowanym chłodzeniem.',
        'Wysoki współczynnik efektywności sezonowej - duży zakres temperatur roboczych',
        'Energooszczędna inteligentna technika cyfrowa z przyjaznym dla użytkownika sterowaniem'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/f1245-pc',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/67004/storage/MDY3MDA0LzAvd2ViLTQ0M3gzODgtcG5n'
    },
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'F1145',
      advantages: [
        'Dla domów wolnostojących i szeregowych',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Dzięki bogatej ofercie wyposażenia dodatkowego, pozwala sterować na przykład układami chłodzenia, podgrzewania wody w basenie i innymi układami ogrzewania/chłodzenia.',
        'Do niskich pomieszczeń lub w przypadku dodatkowego zapotrzebowania na ciepłą wodę',
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/f1145',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/44072/storage/MDQ0MDcyLzAvd2ViLTQ0M3gzODgtcG5n'
    },
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'F1145 PC',
      advantages: [
        'Wydajna pompa ciepła i oddzielny podgrzewacz wody w celu optymalnego dopasowania do potrzeb klienta',
        'Zintegrowane chłodzenie i wysoki współczynnik efektywności sezonowej',
        'Energooszczędna inteligentna technika cyfrowa z przyjaznym dla użytkownika sterowaniem dla maksymalnej jego wygody.',
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/f1145-pc',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/44072/storage/MDQ0MDcyLzAvd2ViLTQ0M3gzODgtcG5n'
    },
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'F1355',
      advantages: [
        'Dla dużych domów i nieruchomości',
        'Sterowanie inwerterowe, dostosowuje się do aktualnego zapotrzebowania na ciepło',
        'Zdalne sterowanie i monitorowanie za pośrednictwem NIBE Uplink',
        'Dzięki bogatej ofercie wyposażenia dodatkowego firmy NIBE, pozwala sterować na przykład układami chłodzenia, podgrzewania wody w basenie i innymi układami ogrzewania/chłodzenia.',
        'Jednoczesne ogrzewanie i przygotowanie ciepłej wody',
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/f1355',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/67071/storage/MDY3MDcxLzAvd2ViLTQ0M3gzODgtcG5n'
    },
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'F1345',
      advantages: [
        'Dla większych nieruchomości',
        'Zdalne sterowanie i monitorowanie za pośrednictwem NIBE Uplink',
        'Możliwość łączenia w układzie kaskadowym do dziewięciu jednostek',
        'Dzięki bogatej ofercie wyposażenia dodatkowego firmy NIBE, pozwala sterować na przykład układami chłodzenia, podgrzewania wody w basenie i innymi układami ogrzewania/chłodzenia.',
        'Jednoczesne ogrzewanie i przygotowanie ciepłej wody',
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/f1345',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/67071/storage/MDY3MDcxLzAvd2ViLTQ0M3gzODgtcG5n'
    },
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'F1226',
      advantages: [
        'Dla domów wolnostojących i szeregowych',
        'Wbudowany podgrzewacz wody'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/f1226',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/67067/storage/MDY3MDY3LzAvd2ViLTQ0M3gzODgtcG5n'
    },
    {
      type: 'gruntowa',
      producer: 'Nibe',
      model: 'F1126',
      advantages: [
        'Dla domów wolnostojących i szeregowych',
        'Do niskich pomieszczeń lub w przypadku dodatkowego zapotrzebowania na ciepłą wodę'
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/gruntowe-pompy-ciepla/f1126',
      img: 'https://assetstore.nibe.se/hcms/v2.3/entity/dam/44162/storage/MDQ0MTYyLzAvd2ViLTQ0M3gzODgtcG5n'
    },
    ///////////////////////////////////////////NIBE
    /////////////////////Wentylacyjne
    {
      type: 'wentylacyjna',
      producer: 'Nibe',
      model: 'F750',
      advantages: [
        'Dla nowych mieszkań',
        'Sterowanie inwerterowe dla uzyskania maksymalnych oszczędności',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Podgrzewane powietrze nawiewane, po zainstalowaniu NIBE SAM 40 (wyposażenie dodatkowe)',
        'Może być podłączona do innego źródła ciepła',
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/wentylacyjne-pompy-ciepla/f750',
      img: 'https://www.nibe.eu/assets/product/1888/NIBE_F730-cut.png?width=210'
    },
    {
      type: 'wentylacyjna',
      producer: 'Nibe',
      model: 'F730',
      advantages: [
        'Dla nowych domów lub wymiany istniejących wentylacyjnych pomp ciepła',
        'Sterowanie inwerterowe dla uzyskania maksymalnych oszczędności',
        'Podgląd i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/wentylacyjne-pompy-ciepla/f730',
      img: 'https://www.nibe.eu/assets/product/1888/NIBE_F730-cut.png?width=210'
    },
    {
      type: 'wentylacyjna',
      producer: 'Nibe',
      model: 'F470',
      advantages: [
        'Dla nowych mieszkań lub wymiany istniejących wentylacyjnych pomp ciepła',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Może być podłączona do innego źródła ciepła',
        'Podgrzewane nawiewane powietrze',
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/wentylacyjne-pompy-ciepla/f470',
      img: 'https://www.nibe.eu/assets/product/4974/NIBEF370-F470-cut.png?width=210'
    },
    {
      type: 'wentylacyjna',
      producer: 'Nibe',
      model: 'F370',
      advantages: [
        'Dla nowych mieszkań lub wymiany istniejących wentylacyjnych pomp ciepła',
        'Nadzór i sterowanie za pomocą NIBE Uplink jako element domu inteligentnego.',
        'Może być podłączona do innego źródła ciepła',
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/wentylacyjne-pompy-ciepla/f370',
      img: 'https://www.nibe.eu/assets/product/4974/NIBEF370-F470-cut.png?width=210'
    },
    {
      type: 'wentylacyjna',
      producer: 'Nibe',
      model: 'F130',
      advantages: [
        'Dla nowych mieszkań lub wymiany istniejących wentylacyjnych pomp ciepła',
        'Może być podłączona do innego źródła ciepła',
      ],
      link: 'https://www.nibe.eu/pl/pl/produkty/pompy-ciepla/wentylacyjne-pompy-ciepla/f130',
      img: 'https://www.nibe.eu/assets/product/273/NIBE_F135-cut.png?width=210'
    },
    ///////////////////////////////////////////GREE
    /////////////////////Pompy
    {
      type: 'powietrzna',
      producer: 'Gree',
      model: 'Versati All in One',
      advantages: [
        'Wykorzystuje energię z otoczenia do ogrzewania lub chłodzenia pomieszczeń, co oznacza, że ​​jest to bardzo efektywny sposób na uzyskanie wymaganej temperatury w budynku, przy minimalnym zużyciu energii elektrycznej.',
        'Pompy ciepła Gree Versati All in One są znane z wysokiej niezawodności i długiej żywotności. Są one projektowane i wykonane z myślą o długotrwałej pracy, co oznacza, że ​​mogą działać przez wiele lat bez większych problemów, a to przekłada się na niższe koszty eksploatacji i większy komfort użytkowania.',
      ],
      link: 'https://gree.pl/produkt/pompa-ciepla-versati-all-in-one/',
      img: '/photos/pompa1.webp'
    },
    {
      type: 'powietrzna',
      producer: 'Gree',
      model: 'Versati Monoblok',
      advantages: [
        'Bardzo łatwa do zainstalowania, ponieważ wszystkie jej elementy, w tym urządzenie grzewcze, zbiornik ciepłej wody użytkowej oraz system chłodzenia, są zintegrowane w jednej obudowie. Dzięki temu oszczędza się miejsce, a także czas i koszty związane z instalacją.',
        'Projektowane i wykonane z myślą o długotrwałej pracy, dzięki czemu są bardzo niezawodne i mogą działać przez wiele lat bez większych problemów, co przekłada się na niższe koszty eksploatacji i większy komfort użytkowania.',
      ],
      link: 'https://gree.pl/produkt/pompa-ciepla-versati-monoblok/',
      img: '/photos/pompa2.webp'
    },
    {
      type: 'powietrzna',
      producer: 'Gree',
      model: 'Versati Split',
      advantages: [
        'Wykorzystuje energię z otoczenia do ogrzewania lub chłodzenia pomieszczeń, co oznacza, że ​​jest to bardzo efektywny sposób na uzyskanie wymaganej temperatury w budynku, przy minimalnym zużyciu energii elektrycznej.',
        'Pompy ciepła Gree Versati Split składają się z jednostki wewnętrznej i zewnętrznej, co pozwala na elastyczny montaż w różnych miejscach budynku, w zależności od potrzeb użytkowników.',
      ],
      link: 'https://gree.pl/produkt/pompa-ciepla-versati-split/',
      img: '/photos/pompa3.webp'
    },


    /////////////////////Klimatyzatory
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'Soyal',
      advantages: [
        'Zaprojektowany w taki sposób, aby zapewnić szybkie i skuteczne chłodzenie pomieszczeń. Dzięki temu klimatyzatorowi szybko zmniejszysz temperaturę w pomieszczeniu i będziesz mógł cieszyć się przyjemnym chłodem nawet w gorące dni.',
        'Jego montaż jest stosunkowo prosty i szybki. Klimatyzator ten nie zajmuje wiele miejsca, a jego konstrukcja pozwala na łatwe i szybkie zamocowanie go na ścianie.',
        'Zaprojektowany tak, aby działać jak najciszej. Dzięki temu klimatyzator ten nie będzie przeszkadzał podczas codziennych czynności i nie będzie zakłócał Twojego spokoju.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-soyal/',
      img: 'https://gree.pl/content/uploads/2021/01/soyal-miniatura.png'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'Amber Prestige',
      advantages: [
        'Zapewnia skuteczne i szybkie chłodzenie pomieszczeń, dzięki czemu możesz cieszyć się przyjemnym chłodem nawet w najgorętsze dni',
        'Posiada wiele zaawansowanych funkcji, takich jak tryb nocny, automatyczne oczyszczanie powietrza i samoczyszczenie, co czyni go nie tylko skutecznym w chłodzeniu, ale również przyjaznym dla środowiska.',
        'Elegancki wygląd, który będzie pasował do każdego wnętrza. Kompaktowa konstrukcja i smukłe kształty sprawiają, że klimatyzator ten nie tylko działa skutecznie, ale też dobrze się prezentuje.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-amber-prestige/',
      img: 'https://gree.pl/content/uploads/2021/01/amber_prestige-miniatura.png'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'U-Crown Champagne',
      advantages: [
        'Zapewnia szybkie i skuteczne chłodzenie pomieszczeń, co czyni go idealnym wyborem na gorące dni. Dzięki temu klimatyzatorowi szybko schłodzisz pomieszczenie i będziesz mógł cieszyć się komfortem nawet w największe upały.',
        'Działa bardzo cicho, co czyni go idealnym wyborem do sypialni lub innych pomieszczeń, w których zależy nam na ciszy i spokoju.',
        'Nowoczesny i elegancki wygląd, który będzie dobrze się prezentował w każdym wnętrzu. Kompaktowa konstrukcja i stylowy design sprawiają, że klimatyzator ten będzie pasował do każdego pomieszczenia i będzie się dobrze komponował z innymi elementami wystroju.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-u-crown/',
      img: 'https://gree.pl/content/uploads/2021/01/u-crown-gold-miniatura.png'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'Moma',
      advantages: [
        'Możesz go łatwo przenosić z pomieszczenia do pomieszczenia. Dzięki temu możesz cieszyć się chłodnym powietrzem tam, gdzie go potrzebujesz, bez konieczności instalowania oddzielnego klimatyzatora w każdym pomieszczeniu.',
        'Bardzo łatwy w obsłudze, dzięki prostemu panelowi sterowania. Możesz łatwo zmieniać ustawienia, takie jak temperatura i prędkość wentylatora, aby dostosować klimatyzator do swoich potrzeb.',
        'Także wentylator i oczyszczacz powietrza w jednym. Dzięki temu urządzeniu możesz cieszyć się chłodnym powietrzem, a jednocześnie oczyszczać powietrze z kurzu i innych zanieczyszczeń.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-przenosny-moma/',
      img: 'https://gree.pl/content/uploads/2021/01/moma-1-870x653.png'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'Shiny',
      advantages: [
        'Możesz go łatwo przemieszczać z pomieszczenia do pomieszczenia. Dzięki temu możesz szybko schłodzić pomieszczenie, w którym aktualnie się znajdujesz, bez konieczności instalacji oddzielnego klimatyzatora w każdym pomieszczeniu.',
        'Zapewnia skuteczne i szybkie chłodzenie pomieszczeń, co jest szczególnie ważne podczas upalnych dni. Dzięki niemu szybko schłodzisz pomieszczenie i będziesz mógł cieszyć się przyjemnym chłodem.',
        'Łatwy w obsłudze, dzięki prostemu panelowi sterowania. Możesz łatwo zmieniać ustawienia, takie jak temperatura i prędkość wentylatora, aby dostosować klimatyzator do swoich potrzeb.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-przenosne-shiny/',
      img: 'https://gree.pl/content/uploads/2021/01/shiny-1-870x653.png'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'GMV',
      advantages: [
        'Zapewnia wydajne i skuteczne chłodzenie lub ogrzewanie pomieszczeń, dzięki czemu jest idealna do zastosowania w dużych budynkach, takich jak biura czy hotele. Konsola ta może być również zintegrowana z systemem wentylacji i klimatyzacji, co zapewnia jeszcze większą wydajność.',
        'Kompaktowy design, który pozwala na łatwe zainstalowanie jej w każdym pomieszczeniu. Dzięki temu można ją zainstalować na ścianie lub suficie, co pozwala na zaoszczędzenie przestrzeni.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-konsole-gmv/',
      img: 'https://gree.pl/content/uploads/2021/02/gmv_konsola_sj2pc010701-870x652.png'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'Free Match',
      advantages: [
        'Oferuje wiele funkcji, takich jak automatyczne przesuwanie kierunku nawiewu powietrza, funkcja nocna oraz inteligentna regulacja temperatury. Dzięki tym funkcjom użytkownik może łatwo dostosować klimatyzator do swoich potrzeb, co pozwala na maksymalny komfort użytkowania.',
        'Zapewnia wydajne i skuteczne chłodzenie lub ogrzewanie pomieszczeń. Dzięki swojej mocy i możliwościom regulacji klimatyzator ten jest idealny do zastosowania w większych pomieszczeniach, takich jak biura, sale konferencyjne lub hotele.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-konsola-free-match/',
      img: '/photos/konsola3.png'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'RAC',
      advantages: [
        'Zaawansowany system klimatyzacji, który zapewnia wydajne i skuteczne chłodzenie lub ogrzewanie pomieszczeń. Dzięki swojej mocy i możliwościom regulacji klimatyzator ten jest idealny do zastosowania w większych pomieszczeniach, takich jak biura, hotele, centra handlowe czy galerie sztuki.',
        'Oferuje wiele funkcji, takich jak automatyczne przesuwanie kierunku nawiewu powietrza, tryb nocny, inteligentna regulacja temperatury oraz wiele innych. Dzięki tym funkcjom użytkownik może łatwo dostosować klimatyzator do swoich potrzeb, co pozwala na maksymalny komfort użytkowania. Konsola RAC może również być sterowana za pomocą aplikacji mobilnej, co pozwala na wygodne zarządzanie klimatyzacją z dowolnego miejsca.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-typu-konsola-rac/',
      img: '/photos/konsola3.png'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'Free Match Plus',
      advantages: [
        'zapewnia szybkie i skuteczne chłodzenie lub ogrzewanie pomieszczenia, dzięki czemu można utrzymać komfortową temperaturę przez cały rok.',
        'Dzięki zastosowaniu nowoczesnych technologii klimatyzator kasetonowy Gree Free Match Plus działa bardzo cicho, co pozwala na komfortowe użytkowanie nawet w nocy.',
        'Elegancki design, który pasuje do wielu wnętrz. Ponadto, dzięki umieszczeniu go w suficie, nie zabiera on miejsca na podłodze czy ścianie.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-kasetonowy-free-match/',
      img: '/photos/kaseton1.webp'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: '4-stronny GMV',
      advantages: [
        'Dzięki zastosowaniu nowoczesnych technologii, klimatyzator kasetonowy U-Match Plus działa bardzo cicho, co pozwala na komfortowe użytkowanie nawet w nocy.',
        'Klimatyzator ten zapewnia wysoką efektywność energetyczną, co przekłada się na niższe rachunki za energię elektryczną.',
        'Wyposażony jest w inteligentne funkcje sterowania, takie jak programowalny timer, czujnik ruchu i zdalne sterowanie za pomocą smartfona, co zapewnia wygodę i oszczędność energii.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-kasetonowy-4-stronny-gmv/',
      img: '/photos/kaseton2.webp'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'U-Match Standard',
      advantages: [
        'Klimatyzator kasetonowy U-Match Standard jest łatwy w instalacji oraz obsłudze, dzięki czemu można szybko i bez problemów korzystać z jego funkcjonalności.',
        'Wyposażony jest w system ochrony przed pleśnią i bakteriami, który dba o czystość powietrza w pomieszczeniu.',
        'Zapewnia wysoką efektywność energetyczną, co przekłada się na niższe rachunki za energię elektryczną.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-kasetonowy-u-match/',
      img: '/photos/kaseton3.webp'
    },
    /////
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'Free Match Plus',
      advantages: [
        'Dzięki zastosowaniu nowoczesnych technologii klimatyzator kanałowy Free Match Plus działa bardzo cicho, co pozwala na komfortowe użytkowanie nawet w nocy. Estetyczny design: Klimatyzator ten posiada elegancki design, który pasuje do wielu wnętrz. Ponadto, dzięki umieszczeniu go w suficie lub podłodze, nie zabiera on miejsca na ścianie.',
        'Posiada funkcję automatycznej regulacji przepływu powietrza, co pozwala na dostosowanie siły nawiewu do wymagań użytkownika.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-kanalowy-free-match/',
      img: '/photos/big1.webp'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'seria A GMV',
      advantages: [
        'Dzięki nowoczesnym technologiom, klimatyzatory z serii A GMV pozwalają na precyzyjną regulację temperatury, co zapewnia maksymalny komfort użytkowania.',
        'Klimatyzatory z serii A GMV działają bardzo cicho, dzięki czemu nie zakłócają spokoju w pomieszczeniu.',
        'Wyposażony w system ochrony przed pleśnią i bakteriami, co zapewnia czyste i zdrowe powietrze w pomieszczeniu.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-kanalowy-wysokiego-sprezu-seria-a-gmv/',
      img: '/photos/big2.webp'
    },
    {
      type: 'klimatyzator',
      producer: 'Gree',
      model: 'U-Match Standard',
      advantages: [
        'Posiada funkcję automatycznej regulacji przepływu powietrza, co pozwala na dostosowanie siły nawiewu do wymagań użytkownika.',
        'Elegancki design, który pasuje do wielu wnętrz. Ponadto, dzięki umieszczeniu go w suficie lub podłodze, nie zabiera on miejsca na ścianie.',
        'Zapewnia wysoką efektywność energetyczną, co przekłada się na niższe rachunki za energię elektryczną.',
      ],
      link: 'https://gree.pl/produkt/klimatyzator-kanalowy-u-match/',
      img: '/photos/big3.webp'
    },
  ]
}