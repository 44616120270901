<template>
  <svg
    width="46"
    height="46"
    fill="none"
    stroke="#ffffff"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path class="delay" d="M15.5 7 12 10.5 8.5 7"></path>
    <path d="M15.5 13 12 16.5 8.5 13"></path>
  </svg>
</template>

<script>
export default {
  props: ['color'],
};
</script>

<style scoped>
svg {
  width: 80px;
  height: 80px;
  z-index: 200;
  cursor: pointer;
  overflow: visible;
  opacity: .8;
}
path{
  animation: pulse 2.5s ease-in-out infinite;
}
.delay{
  animation-delay: 0.2s;
}
@keyframes pulse {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(0px);
  }
}
@media screen and (max-width: 1000px){
svg {
  width: 40px;
  height: 40px;
  z-index: 200;
  cursor: pointer;
  overflow: visible;
}
}
</style>
