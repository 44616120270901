<template>
  <div class="wrapper">
    <header :style="{ backgroundImage: `url(/photos/${bg})` }">
      <h1>{{h1}}</h1>
    </header>
    <main>
      <slot/>
    </main>
  </div>
</template>

<script>
export default {
  props: ['h1', 'bg']
}
</script>

<style scoped>
.wrapper{
  width: 100%;
  height: auto;
  position: relative;
}
header{
  width: 100%;
  height: 40vh;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
header::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.28) 100%);
transform: rotate(-180deg);
}
header h1{
  font-size: 5rem;
  color: white;
  font-weight: 700;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 2%;
  bottom: 2%;
  z-index: 10;
}
@media screen and (max-width: 1000px){
  header h1{
  font-size: 2.5rem;
}
}
</style>