<template>
  <pages-badge h1="Kontakt" bg="hero.jpg">
    <template v-slot:default>
      <section-badge :withoutLine="true" :overflow="true">
        <template v-slot:default>
          <img class="img left" src="/extras/kontaktLeft.svg" alt="" />
          <mail-form />
          <img class="img right" src="/extras/kontaktRight.svg" alt="" />
        </template>
      </section-badge>
    </template>
  </pages-badge>
</template>

<script>
import MailForm from '../components/TheMailForm.vue';
export default {
  components: {
    MailForm,
  },
};
</script>

<style scoped>
.img {
  position: absolute;
  width: 300px;
}
.left {
  top: -15%;
  left: -10%;
}
.right{
  bottom: 0;
  right: -10%;
}
@media screen and (max-width: 800px) {
  .img {
  position: absolute;
  width: 250px;
}
.right{
  visibility: hidden;
}
}
</style>
