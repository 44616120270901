<template>
  <pages-badge h1="REALIZACJE" bg="hero.jpg">
    <template v-slot:default>
      <h1>Trwają prace serwisowe...</h1>
    </template>
  </pages-badge>
</template>

<script>
import PagesBadge from "@/components/utils/PagesBadge.vue";
export default {
  components: { PagesBadge },
};
</script>

<style scoped>
h1 {
  padding: 10rem;
  color: rgb(0, 61, 114);
}
@media screen and (max-width: 550px) {
  h1 {
    padding: 5rem;
    font-size: 1.5rem;
  }
}
</style>
