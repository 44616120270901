<template>
  <header>
    <router-link to="/"
      ><div class="logo"><img src="../../assets/logoKMI.png" /></div
    ></router-link>
    <nav>
      <ul class="nav__links">
        <li @click="toggleDropDown" class="dropDownLink">
          produkty
          <svg
            class=""
            width="46"
            height="46"
            fill="#ffffff"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m4.594 8.912 6.553 7.646a1.126 1.126 0 0 0 1.708 0l6.552-7.646c.625-.73.107-1.857-.854-1.857H5.447c-.961 0-1.48 1.127-.853 1.857Z"
            ></path>
          </svg>
          <ul v-if="dropDownVisible" class="drop__down__menu">
            <router-link to="/pompy" active-class="link-active"
              ><li @click="toggleNav">pompy</li></router-link
            >
            <router-link to="/klimatyzatory"
              ><li @click="toggleNav">klimatyzatory</li></router-link
            >
          </ul>
        </li>
        <router-link to="/realizacje" active-class="link-active"
          ><li @click="toggleNav">realizacje</li></router-link
        >
        <router-link to="/instalacje" active-class="link-active"
          ><li @click="toggleNav">instalacje</li></router-link
        >
        <router-link to="/kontakt" active-class="link-active"
          ><li @click="toggleNav">kontakt</li></router-link
        >
      </ul>
      <div class="bar"></div>
      <div class="icons">
        <bounce-icon
          ><a target="blank" href="https://www.facebook.com/kminstal1"
            ><img src="../../assets/facebook.png" /></a
        ></bounce-icon>
        <bounce-icon
          ><a href="mailto:biuro@kunzklima.pl"
            ><img src="../../assets/mail.png" /></a
        ></bounce-icon>
        <bounce-icon
          ><a href="callto:+48608381322"><img src="../../assets/phone.png" /></a
        ></bounce-icon>
      </div>
      <div class="iconsGreen">
        <a target="blank" href="https://www.facebook.com/kminstal1"
          ><img src="../../assets/facebookGreen.png"
        /></a>
        <a href="callto:+48608381322"
          ><img src="../../assets/phoneGreen.png"
        /></a>
        <a href="mailto:biuro@kunzklima.pl"
          ><img src="../../assets/mailGreen.png"
        /></a>
      </div>
      <the-burger :isOpen="isOpen" @toggle-nav="toggleNav"></the-burger>
    </nav>
  </header>
</template>

<script>
import TheBurger from "@/components/SVGs/TheBurger.vue";
export default {
  components: {
    TheBurger,
  },
  data() {
    return {
      isOpen: false,
      dropDownVisible: false,
    };
  },
  methods: {
    toggleNav() {
      const nav = document.querySelector(".nav__links");
      const navLinks = document.querySelectorAll(".nav__links li");

      nav.classList.toggle("nav__active");
      this.isOpen = !this.isOpen;

      navLinks.forEach((link, index) => {
        if (link.style.animation) {
          console.log("not animate");
          link.style.animation = "";
        } else if (window.innerWidth < 1200) {
          console.log("animate start");
          link.style.animation = `navLinkFade 0.5s ease forwards ${
            index / 7 + 0.051
          }s`;
        }
      });
    },
    toggleDropDown() {
      const firstLinkResponsive = document.querySelector(".dropDownLink");
      firstLinkResponsive.classList.toggle("active");
      this.dropDownVisible = !this.dropDownVisible;
    },
  },
};
</script>

<style scoped>
header {
  display: flex;
  background: rgba(0, 116, 217, 0.5);
  width: 80%;
  height: 78px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  backdrop-filter: blur(3px);
  z-index: 101;
}

nav {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10%;
  justify-content: center;
  overflow: visible !important;
}

.logo {
  float: left;
  width: 298px;
  height: 100%;
  background: #f9f9f9;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  max-width: 100px;
  display: flex;
  align-items: center;
}
nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
  width: 50%;
}
nav ul li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  color: #ffffff;
  position: relative;
  transition: 0.3s all;
  cursor: pointer;
}
li::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.25s ease-out;
}
.drop__down__menu li::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #0074d9;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.25s ease-out;
}
li:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
.nav__links li:hover {
  color: #e5e5e5;
}
.dropDownLink {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
}
.dropDownLink .drop__down__menu {
  position: absolute;
  height: 100px;
  bottom: -105px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  gap: 0.3rem;
  left: 0;
  text-align: left;
  padding: 0.5rem 2rem;
  transition: 0.3s all;
  opacity: 1;
  transform: translateY(0%);
  width: fit-content;
  color: #0074d9;
  background-color: white;
  border-radius: 7px;
  z-index: 10;
}
.dropDownLink .drop__down__menu li {
  font-size: 0.9rem;
  color: #0074d9;
  opacity: 1 !important;
  transform: unset !important;
}
.dropDownLink svg {
  max-width: 15px;
  max-height: 1rem;
}
.icons {
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1.3rem;
}
.icons img,
.iconsGreen img {
  cursor: pointer;
}
.icons img {
  background: none;
  max-width: 30px;
}

.bar {
  width: 3px;
  height: 37px;
  background-color: white;
}
@media screen and (max-width: 1320px) {
  nav ul li {
    font-size: 0.9em;
  }
  .icons img {
    max-width: 25px;
  }
}
.iconsGreen {
  display: none;
}
@media screen and (max-width: 1200px) {
  header {
    background: #f9f9f9;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .logo {
    background: 0;
    width: auto;
    padding: 1rem;
  }
  .logo img {
    max-width: 100px;
  }
  div .logo {
    border-bottom-left-radius: 0;
  }
  .icons {
    display: none;
  }
  .iconsGreen {
    display: flex;
  }
  .iconsGreen img {
    padding: 0 2em;
  }
  nav {
    overflow-y: visible;
  }
  .nav__links {
    position: fixed;
    height: calc(100vh - 78px);
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 5rem 0;
    margin-top: 78px;
    transform: translateX(100%);
    z-index: 1000 !important;
    overflow: visible;
    transition: 0.3s all;
    background-color: rgba(255, 255, 255, 1);
    backdrop-filter: blur(3px);
  }
  li:hover::after {
    transform: scaleX(0);
    transform-origin: left;
  }
  .nav__links li:hover {
    color: #0074d9;
  }
  .nav__active {
    transform: translateX(0%);
  }
  .nav__links li {
    font-size: 1.3rem;
    opacity: 0;
    transform: translateX(1200px) rotate(30deg) scale(10%);
    color: #0074d9;
  }
  .dropDownLink.active {
    margin-bottom: 100px;
  }
  .dropDownLink svg {
    fill: #0074d9;
  }
  .bar {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .iconsGreen img {
    padding: 0 1em;
  }
}
</style>

<style>
@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(1200px) rotate(30deg) scale(10%);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
