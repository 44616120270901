<template>
<div class="wrapper">
  <header>
    <slot name="header"/>
  </header>
  <main>
    <slot/>
  </main>
  <footer>
    <slot name="footer"/>
  </footer>
  <div class="cookies__container">
    <slot name="cookies"/>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: visible;
}
.wrapper{
  width: 100%;
  overflow: hidden;
}
</style>