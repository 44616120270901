<template>
  <app-badge>
    <template v-slot:header>
      <the-header />
    </template>
    <template v-slot:default>
      <router-view v-slot="slotProps">
        <transition name="route" mode="out-in">
          <component :is="slotProps.Component"> </component>
        </transition>
      </router-view>
    </template>
    <template v-slot:footer>
      <the-footer />
    </template>
    <template v-slot:cookies>
      <transition name="cookies">
        <the-cookie-pop-up />
      </transition>
    </template>
  </app-badge>
</template>

<script>
import AppBadge from "./components/utils/AppBadge.vue";
import SectionBadge from "./components/utils/SectionBadge.vue";

import TheHeader from "./components/UI/TheHeader.vue";
import TheFooter from "./components/UI/TheFooter.vue";
import TheCookiePopUp from "./components/UI/TheCookiePopUp.vue";
export default {
  components: {
    AppBadge,
    SectionBadge,
    TheHeader,
    TheFooter,
    TheCookiePopUp,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,500&display=swap");
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.error__h4 {
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  z-index: 100;
}
a {
  text-decoration: none;
  color: inherit;
}
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}
* {
  scrollbar-width: normal;
  scrollbar-color: transparent;
}
*::-webkit-scrollbar {
  width: 0.3vw;
}
*::-webkit-scrollbar-thumb {
  background-color: #0074d9;
  border-radius: 20px;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateX(0%);
}
.route-leave-active {
  transition: all 0.3s ease-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(15%);
}
.route-enter-from {
  opacity: 0;
  transform: translateX(-15%);
}
.cookies-enter-active {
  transition: all 0.3s ease-out;
}
.cookies-enter-to,
.cookies-leave-from {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.cookies-leave-active {
  transition: all 0.3s ease-out;
}
.cookies-leave-to,
.cookies-enter-from {
  opacity: 0;
  transform: translateY(-200px) scale(0.7);
}
</style>
