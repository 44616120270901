<template>
  <pages-badge h1="POMPY" bg="hero.jpg">
    <template v-slot:default>
      <section-badge
        h1="Jeden system wiele funkcji"
        h2="Nasza firma specjalizuje się w instalacji pompy ciepła i klimatyzatorów dla domów, mieszkań i przedsiębiorstw. Zajmujemy się kompleksową obsługą, począwszy od doradztwa technicznego, doboru odpowiedniego urządzenia, aż po montaż i serwis. Działamy na rynku od wielu lat i cieszymy się uznaniem wśród naszych klientów dzięki wysokiej jakości usług oraz indywidualnemu podejściu do każdego zlecenia. <b>Nasza firma jest oficjalnym partnerem</b> marek takich jak NIBE, HEIZTECHNIK oraz GREE."
      >
        <template v-slot:default>
          <the-pompy-plate
            v-for="plate in plates"
            :key="plate"
            :h1="plate.h1"
            :h2="plate.h2"
            :img="plate.img"
            :link="plate.link"
          />
        </template>
      </section-badge>
    </template>
  </pages-badge>
</template>

<script>
import ThePompyPlate from './ThePompyPlate.vue';

export default {
  components: {
    ThePompyPlate,
  },
  data() {
    return {
      plates: [
        {
          link: 'gruntowe',
          img: 'gruntowa.webp',
          h1: 'Gruntowe pompy ciepła',
          h2: 'Gruntowe pompy ciepła to urządzenia, które wykorzystują energię zgromadzoną w glebie do ogrzewania budynków lub wody użytkowej. Wytwarzają one ciepło przez wymianę ciepła pomiędzy gruntowym kolektorem a płynem krążącym wewnątrz pompy. Gruntowe pompy ciepła są bardzo wydajne i ekologiczne, ponieważ wykorzystują energię odnawialną i nie emitują szkodliwych gazów cieplarnianych.',
        },
        {
          link: 'powietrzne',
          img: 'powietrzna.webp',
          h1: 'Powietrzne pompy ciepła',
          h2: 'Powietrzne pompy ciepła to urządzenia, które wykorzystują energię zawartą w powietrzu do ogrzewania budynków lub wody użytkowej. Te urządzenia są łatwe w instalacji i mają niskie koszty eksploatacji w porównaniu do innych rodzajów pomp ciepła, ponieważ nie wymagają instalacji gruntowego kolektora. Powietrzne pompy ciepła są również wysoce wydajne i ekologiczne, ponieważ wykorzystują energię odnawialną i nie emitują szkodliwych gazów cieplarnianych.',
        },
        {
          link: 'wentylacyjne',
          img: 'wentylacyjna.webp',
          h1: 'Wentylacyjne pompy ciepła',
          h2: 'Wentylacyjne pompy ciepła to nowoczesne urządzenia, które zapewniają efektywne wentylowanie oraz ogrzewanie pomieszczeń. Dzięki temu, że wykorzystują one ciepło powietrza wentylacyjnego, są one bardzo wydajne i ekonomiczne. Wentylacyjne pompy ciepła pozwalają na utrzymanie optymalnej wilgotności powietrza, co jest szczególnie istotne w pomieszczeniach o dużym nasileniu wilgoci, takich jak łazienki czy kuchnie.',
        },
      ],
    };
  },
};
</script>

<style scoped></style>
