<template>
  <section :class="{ overflowVisible: overflow }">
    <header>
      <h1>{{ h1 }}</h1>
      <div v-if="!withoutLine" class="line"></div>
      <h2 v-html="h2" v-if="h2"></h2>
    </header>
    <main :class="{ overflowVisible: overflow }">
      <slot></slot>
    </main>
  </section>
</template>

<script>
export default {
  props: ["h1", "h2", "withoutLine", "overflow"],
};
</script>

<style scoped>
section {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: column;
  gap: 2rem;
}
header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
header .line {
  width: 10%;
  height: 4px;
  background-color: #0074d9;
  margin: 1rem 0;
}
section {
  padding: 5%;
  color: #003f76;
}
section h1 {
  font-size: 2rem;
  max-width: 80%;
}
section h2 {
  font-size: 1.2rem;
  max-width: 85%;
  font-weight: 300;
}
main {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
  position: relative;
}
.overflowVisible {
  overflow: visible !important;
}
@media screen and (max-width: 1000px) {
  section h1 {
    font-size: 1.5rem;
    max-width: 80%;
  }
  section h2 {
    font-size: 0.9rem;
    max-width: 95%;
    font-weight: 300;
  }
  main {
    gap: 1rem;
  }
}
</style>
