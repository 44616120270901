<template>
  <div class="card">
    <header>
      <img
        class="producerLogo"
        v-if="card.producer === 'Heiztechnik'"
        src="/partnersLogos/Heiztechnik.png"
        alt=""
      />
      <img
        class="producerLogo"
        v-else-if="card.producer === 'Nibe'"
        src="/partnersLogos/Nibe.png"
        alt=""
      />
      <img class="producerLogo" v-else src="/partnersLogos/Gree.png" alt="" />
      <div class="model">
        <h1>{{ card.model }}</h1>
      </div>
      <div
        :class="{ iffsetRight: offsetImg }"
        :style="{ backgroundImage: `url(${card.img})` }"
        class="img"
      ></div>
    </header>
    <main>
      <ul class="advantages">
        <li v-for="advantage in card.advantages" :key="advantage">
          {{ advantage }}
        </li>
      </ul>
    </main>
    <a target="blank" :href="card.link"
      ><div class="btn"><h1>SZCZEGÓŁY</h1></div></a
    >
  </div>
</template>

<script>
export default {
  props: ["card", "offsetImg"],
  computed: {
    getProducer() {
      console.log(this.card.producer);
      return `url(/partnersLogos/${this.card.producer}.png)`;
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #eeeeee;
  filter: drop-shadow(2px 0px 8px rgba(0, 0, 0, 0.25));
  min-height: 700px;
  width: 25%;
  min-width: 300px;
  padding: 0.5rem;
  padding-bottom: 2rem;
  border-radius: 15px;
  gap: 2rem;
  margin: 1rem;
  cursor: pointer;
}

header {
  background-color: #a8c5de;
  border-radius: 10px;
  width: 100%;
  padding: 25% 0;
  position: relative;
  overflow: hidden;
}
.model {
  background-color: rgba(255, 255, 255, 0.52);
  width: 100%;
  padding: 0.1rem 2rem;
  z-index: 2;
}
.model h1 {
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  max-width: 60%;
}
.producerLogo {
  position: absolute;
  top: 7%;
  left: 5%;
  width: 120px;
}
.img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 170px;
  aspect-ratio: 1/1;
  transition: 0.3s all;
}
.card:hover .img {
  transform: scale(1.3);
}
.iffsetRight {
  right: -20px !important;
}
.btn {
  padding: 0.7rem 2rem;
  border-radius: 30px;
  background-color: rgb(143, 174, 202);
  cursor: pointer;
  position: relative;
  transition: 0.3s all;
  z-index: 222;
}
.btn h1 {
  font-size: 1rem;
  font-weight: 400;
  z-index: 10;
  transition: 0.3s all;
}
.btn:hover.btn h1 {
  text-decoration: underline;
}
ul {
  text-align: left;
  color: black;
  font-weight: 300;
  list-style: disc;
  font-size: 0.9rem;
  max-height: 300px;
  padding: 0 2rem;
  transition: 0.6s all;
  overflow: hidden;
  position: relative;
}
ul::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    360deg,
    #eeeeee -0.17%,
    rgba(217, 217, 217, 0) 99.83%
  );
  width: 100%;
  height: 200px;
  max-height: 20%;
  transition: 0.5s all;
}
.card:hover .advantages {
  max-height: 500px !important;
}
.card:hover .advantages::after {
  max-height: 0% !important;
}
li {
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 400px) {
  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: #eeeeee;
    filter: drop-shadow(2px 0px 8px rgba(0, 0, 0, 0.25));
    min-height: 500px;
    width: 100%;
    min-width: unset;
    padding: 0.5rem;
    padding-bottom: 2rem;
    border-radius: 15px;
    gap: 2rem;
    margin: 1rem;
    cursor: pointer;
  }
  ul {
    text-align: left;
    color: black;
    font-weight: 300;
    list-style: disc;
    font-size: 0.7rem;
    max-height: 200px;
    padding: 0 1rem;
    transition: 0.6s all;
    overflow: hidden;
    position: relative;
  }
}
</style>
