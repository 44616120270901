<template>
  <div class="wrapper">
    <main class="hero">
      <div class="content">
        <h1>Ogrzej lub ochłodź swój dom z nami!</h1>
        <div class="line"></div>
        <h2>
          Komfortowe warunki w każdych warunkach atmosferycznych - instalacje
          pomp ciepła i klimatyzatorów dla domów i przedsiębiorstw.
        </h2>
      </div>
      <div @click="scrollDown" class="scroll__animation">
        <the-arrows />
      </div>
    </main>
    <section-badge
      h1="Jeden system wiele funkcji"
      h2="Nasza firma specjalizuje się w instalacji pompy ciepła i klimatyzatorów dla domów, mieszkań i przedsiębiorstw. Zajmujemy się kompleksową obsługą, począwszy od doradztwa technicznego, doboru odpowiedniego urządzenia, aż po montaż i serwis. Działamy na rynku od wielu lat i cieszymy się uznaniem wśród naszych klientów dzięki wysokiej jakości usług oraz indywidualnemu podejściu do każdego zlecenia. <b>Nasza firma jest oficjalnym partnerem</b> marek takich jak NIBE, HEIZTECHNIK oraz GREE."
    >
      <template v-slot:default>
        <the-home-section-card
          v-for="card in homeSectionCards"
          :key="card"
          :card="card"
        />
      </template>
    </section-badge>
  </div>
</template>

<script>
import SectionBadge from "@/components/utils/SectionBadge.vue";
import TheHomeSectionCard from "../components/UI/TheHomeSectionCard.vue";
import TheArrows from "../components/animations/ArrowsDown.vue";
export default {
  components: { SectionBadge, TheHomeSectionCard, TheArrows },
  data() {
    return {
      homeSectionCards: [
        {
          id: 1,
          h1: "Komfort termiczny",
          h2: "Dzięki naszym rozwiązaniom, możesz cieszyć się idealną temperaturą w pomieszczeniach przez cały rok. Klimatyzacja zapewni Ci przyjemne chłodzenie w upalne dni, a pompa ciepła ogrzewanie w chłodniejszych miesiącach. ",
          imgUrl: "2.jpg",
        },
        {
          id: 2,
          h1: "Oszczędność energii",
          h2: "Nasze urządzenia wykorzystują energię odnawialną i są wysoce wydajne, co pozwala na oszczędność na rachunkach za energię elektryczną.",
          imgUrl: "1.jpg",
        },
        {
          id: 3,
          h1: "Zdrowe powietrze",
          h2: "Klimatyzacja oraz pompy ciepła pozwalają na utrzymanie czystego i zdrowego powietrza w pomieszczeniach dzięki systemom filtracji i oczyszczania powietrza.",
          imgUrl: "5.jpg",
        },
        {
          id: 4,
          h1: "Instalacja",
          h2: "Dzięki naszej specjalizacji w dziedzinie instalacji klimatyzacji i pomp ciepła, oferujemy łatwe i szybkie instalacje, a także profesjonalną obsługę posprzedażową",
          imgUrl: "3.jpg",
        },
        {
          id: 5,
          h1: "Ekologia",
          h2: "Nasze rozwiązania są przyjazne dla środowiska, ponieważ wykorzystują energię odnawialną i mają niski wpływ na środowisko",
          imgUrl: "4.jpg",
        },
        {
          id: 6,
          h1: "Wartość nieruchomości",
          h2: "Posiadanie klimatyzacji lub pompy ciepła może wpłynąć na wartość nieruchomości, co może być korzystne w przypadku sprzedaży lub wynajmu nieruchomości w przyszłości",
          imgUrl: "5.jpg",
        },
      ],
    };
  },
  methods: {
    scrollDown() {
      const height = document.querySelector(".hero").clientHeight;
      console.log(height);
      window.scrollTo({
        top: height,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.scroll__animation {
  position: absolute;
  bottom: 2%;
  right: calc(50% - 50px);
  z-index: 3;
  overflow: visible;
}
.wrapper {
  width: 100%;
  height: auto;
  position: relative;
}
.hero {
  position: relative;
}
main {
  width: 100%;
  height: 100vh;
  background-image: url("../../public/photos/hero2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
}
main::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0.28) 100%
  );
  transform: rotate(0deg);
}
.content {
  padding: 7rem;
  min-height: 50%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    118.79deg,
    #0074d9 14.39%,
    rgba(56, 176, 0, 0) 66.41%
  );
  color: white;
  z-index: 2;
}
.content div {
  background-color: white;
  height: 6px;
  width: 15%;
  margin: 1.5rem 0;
}
.content h1,
.content h2 {
  z-index: 2;
  text-align: left;
}
.content h1 {
  font-size: 2.7rem;
  font-weight: 600;
  max-width: 80%;
}
.content h2 {
  font-weight: 300;
  max-width: 50%;
}
@media screen and (max-width: 1000px) {
  .scroll__animation {
    right: calc(50% - 20px);
  }
  .content {
    padding: 2rem;
  }
  .content div {
    background-color: white;
    height: 4px;
    width: 15%;
    margin: 1.5rem 0;
  }
  .content h1 {
    font-size: 1.8rem;
    font-weight: 600;
    max-width: 90%;
  }
  .content h2 {
    font-weight: 300;
    max-width: 70%;
    font-size: 1rem;
  }
}
</style>
