<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <div
      :class="{ left: checkPairCard(card.id) }"
      class="card photo"
      :style="{ backgroundImage: `url(/SectionCards/${card.imgUrl})` }"
    >
      <div class="content">
        <h1>{{ card.h1 }}</h1>
        <h2>{{ card.h2 }}</h2>
      </div>
    </div>
  </transition>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  props: ["card"],
  methods: {
    checkPairCard(id) {
      if (id % 2 !== 0) {
        console.log(id);
        return false;
      } else {
        return true;
      }
    },
  },
  //gsap:
  setup() {
    const beforeEnterTitle = (el) => {
      el.style.opacity = 0;
      el.style.transform = "translateX(-100%)";
    };
    const enterTitle = (el) => {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          start: "center, 80%",
        },
        opacity: 1,
        x: 0,
        duration: 0.8,
        delay: 0.2,
      });
    };
    const beforeEnter = (el) => {
      el.style.opacity = 0;
      el.style.transform = "translateY(20%)";
    };
    const enter = (el) => {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          start: "center, 80%",
        },
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "elastic.out(1, 0.8)",
        delay: el.dataset.index * 0.1,
      });
    };
    return { beforeEnter, enter, beforeEnterTitle, enterTitle };
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: auto;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;
}
.content {
  background-color: #a8c5de;
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  text-align: left;
  padding: 3rem 2rem;
}
.left {
  flex-direction: row-reverse;
}
.content h1 {
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}
.content h2 {
  font-weight: 300;
  font-size: 0.9rem;
}
@media screen and (max-width: 1000px) {
  .card {
    min-height: 350px;
  }
  .content {
    background-color: rgb(147, 181, 212);
    height: 100%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    text-align: left;
    padding: 3rem 2rem;
  }
  .content h1 {
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .content h2 {
    font-weight: 300;
    font-size: 0.9rem;
  }
}
</style>
